.rec .rec-slider-container {
    margin: 0;
}

.rec .rec-dot {
    height: 5px;
    width: 5px;
}

.rec .rec-dot_active {
    height: 5px;
    width: 5px;
    background-color: #EB8B2A !important;
    box-shadow: rgb(235, 139, 42) 0px 0px 1px 3px !important;   
}

.rec .rec-item-wrapper {
    overflow: visible;   
}

.rec .rec-carousel {
    overflow: visible;   
}

.rec .rec-carousel-item {
    padding: 0;
    overflow: visible;
}