  .rotate {
      animation: rotation 4s infinite linear;
  }
  @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(-359deg);
      }
  }