* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.bottom-nav {
  width: 100%;
  height: 60px;
  padding: 0% 10% 0% 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  border-top: 1px solid rgb(230, 230, 230);
  background: #ffff
}
.bn-tab {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 
.active-tab {
  background-color: #EB8B2A;
} */

.css-1x05oka[aria-selected='true'],
.css-1x05oka[data-selected] {
  color: white !important;
}

.css-5859au[aria-selected='true'],
.css-5859au[data-selected] {
  color: white !important;
}

.css-9ye717[aria-selected='true'],
.css-9ye717[data-selected] {
  color: white !important;
}

/*.css-ko66tc[aria-selected='true'] {
  color: teal !important;
}*/
.googlemap {
  display: inline !important;
}

.css-ko66tc[aria-selected='true'] {
  color: #529c94 !important;
  border-color: orange !important;
}
